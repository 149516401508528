const allFrontenMaterialsArray = [
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/01_standard_weiss.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg",
    alt: "weiß",
    caption: "01 Standard-Weiß",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/02_vulkanschwarz.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg",
    alt: "vulkanschwarz",
    caption: "02 Vulkanschwarz",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/03_cuvo.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/03_cuvo.jpg",
    alt: "cuvo",
    caption: "03 Cuvo",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/04_sandbeige.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/04_sandbeige.jpg",
    alt: "sandbeige",
    caption: "04 Sandbeige",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/05_nussbaum_optik.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/05_nussbaum_optik.jpg",
    alt: "nussbaum optik",
    caption: "05 Nussbaum Optik",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/06_eiche_astig_optik.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/06_eiche_astig_optik.jpg",
    alt: "eiche astig",
    caption: "06 Wildeiche-Optik",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/07_lichtgrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/07_lichtgrau.jpg",
    alt: "lichtgrau",
    caption: "07 Lichtgrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/08_antrazithgrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/08_antrazithgrau.jpg",
    alt: "anthrazitgrau",
    caption: "08 Antrazithgrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/09_eichefurnier.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/09_eichefurnier.jpg",
    alt: "eichefurnier",
    caption: "09 Eichefurnier",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/10_nussbaumfurnier.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/10_nussbaumfurnier.jpg",
    alt: "nussbaumfurnier",
    caption: "10 Nussbaumfurnier",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/11_alpinweiss.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/11_alpin_weiss.jpg",
    alt: "alpinweiß",
    caption: "11 Alpin-Weiß",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/12_schwarz.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/12_schwarz.jpg",
    alt: "schwarz",
    caption: "12 Schwarz",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/13_seidengrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/13_seidengrau.jpg",
    alt: "seidengrau",
    caption: "13 Seidengrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/14_quarzgrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/14_quarzgrau.jpg",
    alt: "quarzgrau",
    caption: "14 Quarzgrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/15_perlkupfer.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/15_perlkupfer.jpg",
    alt: "perlkupfer",
    caption: "15 Perlkupfer",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/16_fernblau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/16_fernblau.jpg",
    alt: "fernblau",
    caption: "16 Fernblau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/17_rapsgelb.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/17_rapsgelb.jpg",
    alt: "rapsgelb",
    caption: "17 Rapsgelb",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/18_resedagruen.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/18_resedagruen.jpg",
    alt: "resedagrün",
    caption: "18 Resedagrün",
  },
];

const kunststoffMaterialsArray = [
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/01_standard_weiss.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg",
    alt: "weiß",
    caption: "01 Standard-Weiß",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/02_vulkanschwarz.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg",
    alt: "vulkanschwarz",
    caption: "02 Vulkanschwarz",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/03_cuvo.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/03_cuvo.jpg",
    alt: "cuvo",
    caption: "03 Cuvo",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/04_sandbeige.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/04_sandbeige.jpg",
    alt: "sandbeige",
    caption: "04 Sandbeige",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/05_nussbaum_optik.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/05_nussbaum_optik.jpg",
    alt: "nussbaum optik",
    caption: "05 Nussbaum Optik",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/06_eiche_astig_optik.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/06_eiche_astig_optik.jpg",
    alt: "eiche astig",
    caption: "06 Wildeiche-Optik",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/07_lichtgrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/07_lichtgrau.jpg",
    alt: "lichtgrau",
    caption: "07 Lichtgrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/08_antrazithgrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/08_antrazithgrau.jpg",
    alt: "anthrazitgrau",
    caption: "08 Antrazithgrau",
  },
];

const echtholzMaterialsArray = [
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/09_eichefurnier.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/09_eichefurnier.jpg",
    alt: "eichefurnier",
    caption: "09 Eichefurnier",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/10_nussbaumfurnier.jpg",
    texture:
      "https://app.4kantdesign.de/images/Materials/10_nussbaumfurnier.jpg",
    alt: "nussbaumfurnier",
    caption: "10 Nussbaumfurnier",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/11_alpinweiss.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/11_alpin_weiss.jpg",
    alt: "alpinweiß",
    caption: "11 Alpin-Weiß",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/12_schwarz.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/12_schwarz.jpg",
    alt: "schwarz",
    caption: "12 Schwarz",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/13_seidengrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/13_seidengrau.jpg",
    alt: "seidengrau",
    caption: "13 Seidengrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/14_quarzgrau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/14_quarzgrau.jpg",
    alt: "quarzgrau",
    caption: "14 Quarzgrau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/15_perlkupfer.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/15_perlkupfer.jpg",
    alt: "perlkupfer",
    caption: "15 Perlkupfer",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/16_fernblau.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/16_fernblau.jpg",
    alt: "fernblau",
    caption: "16 Fernblau",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/17_rapsgelb.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/17_rapsgelb.jpg",
    alt: "rapsgelb",
    caption: "17 Rapsgelb",
  },
  {
    path: "https://app.4kantdesign.de/images/materialsPreview/18_resedagruen.jpg",
    texture: "https://app.4kantdesign.de/images/Materials/18_resedagruen.jpg",
    alt: "resedagrün",
    caption: "18 Resedagrün",
  },
];

export {
  allFrontenMaterialsArray,
  kunststoffMaterialsArray,
  echtholzMaterialsArray,
};
