import React, { Suspense, useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import styles from "../../styles/wizard.module.scss";
import btnStyles from "../../styles/btnStyles.module.scss";
import configuratorStyles from "../../styles/configurator.module.scss";
import sidebarStyles from "../../styles/sidebar.module.scss";
import MaterialOption from "./MaterialOption";
import AllFrontenMaterials from "../configurator/AllFrontenMaterials";
import KunststoffFrontenMaterials from "../configurator/KunststoffFrontenMaterials";
import EchtholzLackFrontenMaterials from "../configurator/EchtholzLackFrontenMaterials";
import imagepaths from "../imagepaths/imagepaths";
import * as THREE from "three";
import LoadingObserver from "../configurator/LoadingObserver";
import { Canvas } from "@react-three/fiber";
import LoadingAnimation from "../configurator/LoadingAnimation";
import ActiveMaterial from "../configurator/ActiveMaterial";
import MaterialSelection from "../configurator/MaterialSelection";
import { Raycaster, Vector3 } from "three";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const Wizard = () => {
  const {
    setWizardReady,
    wizardPreset,
    setWizardPreset,
    frontenTexture,
    setFrontenTexture,
    korpusTexture,
    setKorpusTexture,
    griffleistenTexture,
    setGriffleistenTexture,
    sockelTexture,
    setSockelTexture,
    loading,
    isSelectedElement,
    selectedAll,
    globalFrontenTexture,
    setGlobalFrontenTexture,
    globalKorpusTexture,
    setGlobalKorpusTexture,
    globalGriffleistenTexture,
    setGlobalGriffleistenTexture,
    globalSockelTexture,
    setGlobalSockelTexture,
    globalSeitenwangenTexture,
    setGlobalSeitenwangenTexture,
    setElementsMaterials,
    indexOfModel,
    elements,
    setElements,
    handleShowVariations,
    setHandleShowVariations,
    showInspirations,
    setShowInspirations,
    variationToAdd,
    setVariationToAdd,
    variationAdded,
    setVariationAdded,
    setElementsRendering,
    setHeightControls,
    setTotalHeight,
    setCompleteWidth,
    setRaycasterRowIntersections,
    setElementToAdd,
    setIsHeightEqual,
  } = useGlobalContext();

  // const imageSourcesToPreload = ["/images/x.svg"];

  const [currentStep, setCurrentStep] = useState(1);
  const [elementToApplyMaterialOn, setElementToApplyMaterialOn] =
    useState("Fronten");
  const [materialTypeWizard, setMaterialTypeWizard] = useState("alle");

  useEffect(() => {
    if (variationAdded === "Option1") {
      setWizardPreset(true);
      setElementToAdd("Model_110110_600x734_fb");
      const createRaycasters = () => {};

      const firstRaycasterData = {
        ray: {
          origin: { x: -1.1, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };

      const firstRaycaster = new Raycaster();
      firstRaycaster.far = 20;
      const firstRaycasterStart = new Vector3(0, 0, 0);

      const firstOrigin = new Vector3(
        firstRaycasterData.ray.origin.x,
        firstRaycasterData.ray.origin.y,
        firstRaycasterData.ray.origin.z
      );
      const firstDirection = new Vector3(
        firstRaycasterData.ray.direction.x,
        firstRaycasterData.ray.direction.y,
        firstRaycasterData.ray.direction.z
      );

      firstRaycaster.ray.origin.copy(firstOrigin);
      firstRaycaster.ray.direction.copy(firstDirection);
      firstRaycaster.near = firstRaycasterData.near;
      firstRaycaster.far = firstRaycasterData.far;

      const secondRaycasterData = {
        ray: {
          origin: { x: -0.5, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };

      const secondRaycaster = new Raycaster();
      secondRaycaster.far = 20;
      const secondRaycasterStart = new Vector3(0, 0, 0);

      const secondOrigin = new Vector3(
        secondRaycasterData.ray.origin.x,
        secondRaycasterData.ray.origin.y,
        secondRaycasterData.ray.origin.z
      );
      const secondDirection = new Vector3(
        secondRaycasterData.ray.direction.x,
        secondRaycasterData.ray.direction.y,
        secondRaycasterData.ray.direction.z
      );

      secondRaycaster.ray.origin.copy(secondOrigin);
      secondRaycaster.ray.direction.copy(secondDirection);
      secondRaycaster.near = secondRaycasterData.near;
      secondRaycaster.far = secondRaycasterData.far;

      const thirdRaycasterData = {
        ray: {
          origin: { x: 0.1, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };

      const thirdRaycaster = new Raycaster();
      thirdRaycaster.far = 20;
      const thirdRaycasterStart = new Vector3(0, 0, 0);

      const thirdOrigin = new Vector3(
        thirdRaycasterData.ray.origin.x,
        thirdRaycasterData.ray.origin.y,
        thirdRaycasterData.ray.origin.z
      );
      const thirdDirection = new Vector3(
        thirdRaycasterData.ray.direction.x,
        thirdRaycasterData.ray.direction.y,
        thirdRaycasterData.ray.direction.z
      );

      thirdRaycaster.ray.origin.copy(thirdOrigin);
      thirdRaycaster.ray.direction.copy(thirdDirection);
      thirdRaycaster.near = thirdRaycasterData.near;
      thirdRaycaster.far = thirdRaycasterData.far;

      const fourthRaycasterData = {
        ray: {
          origin: { x: 0.55, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };

      const fourthRaycaster = new Raycaster();
      fourthRaycaster.far = 20;
      const fourthRaycasterStart = new Vector3(0, 0, 0);

      const fourthOrigin = new Vector3(
        fourthRaycasterData.ray.origin.x,
        fourthRaycasterData.ray.origin.y,
        fourthRaycasterData.ray.origin.z
      );
      const fourthDirection = new Vector3(
        fourthRaycasterData.ray.direction.x,
        fourthRaycasterData.ray.direction.y,
        fourthRaycasterData.ray.direction.z
      );

      fourthRaycaster.ray.origin.copy(fourthOrigin);
      fourthRaycaster.ray.direction.copy(fourthDirection);
      fourthRaycaster.near = fourthRaycasterData.near;
      fourthRaycaster.far = fourthRaycasterData.far;

      const fifthRaycasterData = {
        ray: {
          origin: { x: 1, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };

      const fifthRaycaster = new Raycaster();
      fifthRaycaster.far = 20;
      const fifthRaycasterStart = new Vector3(0, 0, 0);

      const fifthOrigin = new Vector3(
        fifthRaycasterData.ray.origin.x,
        fifthRaycasterData.ray.origin.y,
        fifthRaycasterData.ray.origin.z
      );
      const fifthDirection = new Vector3(
        fifthRaycasterData.ray.direction.x,
        fifthRaycasterData.ray.direction.y,
        fifthRaycasterData.ray.direction.z
      );

      fifthRaycaster.ray.origin.copy(fifthOrigin);
      fifthRaycaster.ray.direction.copy(fifthDirection);
      fifthRaycaster.near = fifthRaycasterData.near;
      fifthRaycaster.far = fifthRaycasterData.far;

      const sixthRaycasterData = {
        ray: {
          origin: { x: 1.6, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };

      const sixthRaycaster = new Raycaster();
      sixthRaycaster.far = 20;
      const sixthRaycasterStart = new Vector3(0, 0, 0);

      const sixthOrigin = new Vector3(
        sixthRaycasterData.ray.origin.x,
        sixthRaycasterData.ray.origin.y,
        sixthRaycasterData.ray.origin.z
      );
      const sixthDirection = new Vector3(
        sixthRaycasterData.ray.direction.x,
        sixthRaycasterData.ray.direction.y,
        sixthRaycasterData.ray.direction.z
      );

      sixthRaycaster.ray.origin.copy(sixthOrigin);
      sixthRaycaster.ray.direction.copy(sixthDirection);
      sixthRaycaster.near = sixthRaycasterData.near;
      sixthRaycaster.far = sixthRaycasterData.far;

      setHeightControls([
        firstRaycaster,
        secondRaycaster,
        thirdRaycaster,
        fourthRaycaster,
        fifthRaycaster,
        sixthRaycaster,
      ]);

      setElements([
        {
          x: -1,
          y: "-0.54300",
          z: -0.5,
          model: "Model_110110_600x734_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1.3095,
          y: 0.23849999999999996,
          z: -0.5,
          model: "Model_170900_2457",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 2.0094999999999996,
          y: 0.23849999999999996,
          z: -0.5,
          model: "Model_180900_2457",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.39999999999999997,
          y: "-0.81534",
          z: -0.5,
          model: "Model_130110_600x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 0.2,
          y: "-0.81634",
          z: -0.5,
          model: "Model_130110_600x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.09999999999999998,
          y: -0.5450099990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -0.09999999999999998,
          y: -0.6796749990844726,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.09999999999999998,
          y: -0.2746799981689452,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -0.09999999999999998,
          y: -0.40934499816894526,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -1,
          y: 0.678,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: -0.39999999999999997,
          y: 0.6749850027465822,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: 0.2,
          y: 0.6749850027465822,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: -0.3999999999999998,
          y: -0.13901499725341787,
          z: -0.5,
          model: "Model_160900_1800",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 0.65,
          y: "0.26899",
          z: -0.5,
          model: "Model_140110_300x2362_offen_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.1,
          y: "-0.54501",
          z: -0.5,
          model: "Model_110110_600x734_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 0.04999999999999982,
          y: -0.95,
          z: -0.5,
          model: "Model_151300_2700",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1.7000000000000002,
          y: "-0.54501",
          z: -0.5,
          model: "Model_120110_600x734_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.7,
          y: -0.95,
          z: -0.5,
          model: "Model_150300_600",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1.1,
          y: 0.6759900000000001,
          z: -0.5,
          model: "Model_110310_600x1548_fb",
        },
        {
          x: 1.7000000000000002,
          y: 0.6759900000000001,
          z: -0.5,
          model: "Model_120310_600x1548_fb",
        },
        {
          x: 1.4000000000000001,
          y: -0.13800999999999997,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
      ]);

      setElementsRendering([
        {
          x: -1,
          y: "-0.54300",
          z: -0.5,
          model: "Model_110110_600x734_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1.3095,
          y: 0.23849999999999996,
          z: -0.5,
          model: "Model_170900_2457",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 2.0094999999999996,
          y: 0.23849999999999996,
          z: -0.5,
          model: "Model_180900_2457",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.39999999999999997,
          y: "-0.81534",
          z: -0.5,
          model: "Model_130110_600x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 0.2,
          y: "-0.81634",
          z: -0.5,
          model: "Model_130110_600x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.09999999999999998,
          y: -0.5450099990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -0.09999999999999998,
          y: -0.6796749990844726,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.09999999999999998,
          y: -0.2746799981689452,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -0.09999999999999998,
          y: -0.40934499816894526,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -1,
          y: 0.678,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: -0.39999999999999997,
          y: 0.6749850027465822,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: 0.2,
          y: 0.6749850027465822,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: -0.3999999999999998,
          y: -0.13901499725341787,
          z: -0.5,
          model: "Model_160900_1800",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 0.65,
          y: "0.26899",
          z: -0.5,
          model: "Model_140110_300x2362_offen_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.1,
          y: "-0.54501",
          z: -0.5,
          model: "Model_110110_600x734_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 0.04999999999999982,
          y: -0.95,
          z: -0.5,
          model: "Model_151300_2700",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1.7000000000000002,
          y: "-0.54501",
          z: -0.5,
          model: "Model_120110_600x734_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.7,
          y: -0.95,
          z: -0.5,
          model: "Model_150300_600",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1.1,
          y: 0.6759900000000001,
          z: -0.5,
          model: "Model_110310_600x1548_fb",
        },
        {
          x: 1.1,
          y: -0.13800999999999997,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
      ]);
      setElementsMaterials([
        {
          front: globalFrontenTexture,
          korpus: globalFrontenTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSeitenwangenTexture,
          griffleiste: false,
          sockel: false,
          wange: true,
        },
        {
          front: null,
          korpus: globalSeitenwangenTexture,
          griffleiste: false,
          sockel: false,
          wange: true,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSockelTexture,
          griffleiste: false,
          sockel: true,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSockelTexture,
          griffleiste: false,
          sockel: true,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
      ]);
      setTotalHeight(2.47);
      setCompleteWidth(3.3);
      setIsHeightEqual(true);
    } else if (variationAdded === "Option2") {
      setWizardPreset(true);
      setElementToAdd("Model_130210_1200x19133_schub");
      const firstRaycasterData = {
        ray: {
          origin: { x: -1.1, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const firstRaycaster = new Raycaster();
      firstRaycaster.far = 20;
      const firstRaycasterStart = new Vector3(0, 0, 0);
      const firstOrigin = new Vector3(
        firstRaycasterData.ray.origin.x,
        firstRaycasterData.ray.origin.y,
        firstRaycasterData.ray.origin.z
      );
      const firstDirection = new Vector3(
        firstRaycasterData.ray.direction.x,
        firstRaycasterData.ray.direction.y,
        firstRaycasterData.ray.direction.z
      );
      firstRaycaster.ray.origin.copy(firstOrigin);
      firstRaycaster.ray.direction.copy(firstDirection);
      firstRaycaster.near = firstRaycasterData.near;
      firstRaycaster.far = firstRaycasterData.far;

      const secondRaycasterData = {
        ray: {
          origin: { x: 0.09999999999999995, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const secondRaycaster = new Raycaster();
      secondRaycaster.far = 20;
      const secondRaycasterStart = new Vector3(0, 0, 0);
      const secondOrigin = new Vector3(
        secondRaycasterData.ray.origin.x,
        secondRaycasterData.ray.origin.y,
        secondRaycasterData.ray.origin.z
      );
      const secondDirection = new Vector3(
        secondRaycasterData.ray.direction.x,
        secondRaycasterData.ray.direction.y,
        secondRaycasterData.ray.direction.z
      );
      secondRaycaster.ray.origin.copy(secondOrigin);
      secondRaycaster.ray.direction.copy(secondDirection);
      secondRaycaster.near = secondRaycasterData.near;
      secondRaycaster.far = secondRaycasterData.far;

      const thirdRaycasterData = {
        ray: {
          origin: { x: 0.9999999999999999, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const thirdRaycaster = new Raycaster();
      thirdRaycaster.far = 20;
      const thirdRaycasterStart = new Vector3(0, 0, 0);
      const thirdOrigin = new Vector3(
        thirdRaycasterData.ray.origin.x,
        thirdRaycasterData.ray.origin.y,
        thirdRaycasterData.ray.origin.z
      );
      const thirdDirection = new Vector3(
        thirdRaycasterData.ray.direction.x,
        thirdRaycasterData.ray.direction.y,
        thirdRaycasterData.ray.direction.z
      );
      thirdRaycaster.ray.origin.copy(thirdOrigin);
      thirdRaycaster.ray.direction.copy(thirdDirection);
      thirdRaycaster.near = thirdRaycasterData.near;
      thirdRaycaster.far = thirdRaycasterData.far;

      setHeightControls([firstRaycaster, secondRaycaster, thirdRaycaster]);
      setElements([
        {
          x: -1,
          y: "-0.81533",
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1.6095000000000002,
          y: 0.10332999999999995,
          z: -0.5,
          model: "Model_170800_218666",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.4094999999999998,
          y: 0.10332999999999995,
          z: -0.5,
          model: "Model_180800_218666",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1,
          y: -0.5449999990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: 0.19999999999999996,
          y: "-0.81633",
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.4,
          y: -0.95,
          z: -0.5,
          model: "Model_151200_2400",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 0.19999999999999996,
          y: -0.5459999990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -0.40000000000000013,
          y: -0.6806649990844726,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 1.0999999999999999,
          y: "-0.81733",
          z: -0.5,
          model: "Model_130110_600x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.0999999999999999,
          y: -0.95,
          z: -0.5,
          model: "Model_150300_600",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1.0999999999999999,
          y: -0.5469999990844726,
          z: -0.5,
          model: "Model_130110_600x19133_schub",
        },
        {
          x: 1.0999999999999999,
          y: -0.6816649990844726,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -1.3,
          y: 0.40466500183105475,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: -0.7,
          y: 0.40466500183105475,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: -0.10000000000000003,
          y: 0.40366500183105475,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: 0.49999999999999994,
          y: 0.40366500183105475,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: -0.40000000000000013,
          y: -0.4103349981689453,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 1.0999999999999999,
          y: 0.40266500183105475,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: 1.0999999999999999,
          y: -0.4113349981689453,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
      ]);
      setElementsRendering([
        {
          x: -1,
          y: "-0.81533",
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1.6095000000000002,
          y: 0.10332999999999995,
          z: -0.5,
          model: "Model_170800_218666",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.4094999999999998,
          y: 0.10332999999999995,
          z: -0.5,
          model: "Model_180800_218666",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1,
          y: -0.5449999990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: 0.19999999999999996,
          y: "-0.81633",
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.4,
          y: -0.95,
          z: -0.5,
          model: "Model_151200_2400",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 0.19999999999999996,
          y: -0.5459999990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -0.40000000000000013,
          y: -0.6806649990844726,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 1.0999999999999999,
          y: "-0.81733",
          z: -0.5,
          model: "Model_130110_600x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.0999999999999999,
          y: -0.95,
          z: -0.5,
          model: "Model_150300_600",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1.0999999999999999,
          y: -0.5469999990844726,
          z: -0.5,
          model: "Model_130110_600x19133_schub",
        },
        {
          x: 1.0999999999999999,
          y: -0.6816649990844726,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -1.3,
          y: 0.40466500183105475,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: -0.7,
          y: 0.40466500183105475,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: -0.10000000000000003,
          y: 0.40366500183105475,
          z: -0.5,
          model: "Model_110320_600x1548_kl",
        },
        {
          x: 0.49999999999999994,
          y: 0.40366500183105475,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: -0.40000000000000013,
          y: -0.4103349981689453,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 1.0999999999999999,
          y: 0.40266500183105475,
          z: -0.5,
          model: "Model_120320_600x1548_kl",
        },
        {
          x: 1.0999999999999999,
          y: -0.4113349981689453,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
      ]);
      setElementsMaterials([
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSeitenwangenTexture,
          griffleiste: false,
          sockel: false,
          wange: true,
        },
        {
          front: null,
          korpus: globalSeitenwangenTexture,
          griffleiste: false,
          sockel: false,
          wange: true,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSockelTexture,
          griffleiste: false,
          sockel: true,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSockelTexture,
          griffleiste: false,
          sockel: true,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
      ]);
      setTotalHeight(2.19);
      setCompleteWidth(3);
      setIsHeightEqual(true);
    } else if (variationAdded === "Option3") {
      setWizardPreset(true);
      setElementToAdd("Model_130210_1200x19133_schub");
      const firstRaycasterData = {
        ray: {
          origin: { x: -1.1, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const firstRaycaster = new Raycaster();
      firstRaycaster.far = 20;
      const firstRaycasterStart = new Vector3(0, 0, 0);
      const firstOrigin = new Vector3(
        firstRaycasterData.ray.origin.x,
        firstRaycasterData.ray.origin.y,
        firstRaycasterData.ray.origin.z
      );
      const firstDirection = new Vector3(
        firstRaycasterData.ray.direction.x,
        firstRaycasterData.ray.direction.y,
        firstRaycasterData.ray.direction.z
      );
      firstRaycaster.ray.origin.copy(firstOrigin);
      firstRaycaster.ray.direction.copy(firstDirection);
      firstRaycaster.near = firstRaycasterData.near;
      firstRaycaster.far = firstRaycasterData.far;
      const secondRaycasterData = {
        ray: {
          origin: { x: -0.20000000000000004, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const secondRaycaster = new Raycaster();
      secondRaycaster.far = 20;
      const secondRaycasterStart = new Vector3(0, 0, 0);
      const secondOrigin = new Vector3(
        secondRaycasterData.ray.origin.x,
        secondRaycasterData.ray.origin.y,
        secondRaycasterData.ray.origin.z
      );
      const secondDirection = new Vector3(
        secondRaycasterData.ray.direction.x,
        secondRaycasterData.ray.direction.y,
        secondRaycasterData.ray.direction.z
      );
      secondRaycaster.ray.origin.copy(secondOrigin);
      secondRaycaster.ray.direction.copy(secondDirection);
      secondRaycaster.near = secondRaycasterData.near;
      secondRaycaster.far = secondRaycasterData.far;
      const thirdRaycasterData = {
        ray: {
          origin: { x: 0.3999999999999999, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const thirdRaycaster = new Raycaster();
      thirdRaycaster.far = 20;
      const thirdRaycasterStart = new Vector3(0, 0, 0);
      const thirdOrigin = new Vector3(
        thirdRaycasterData.ray.origin.x,
        thirdRaycasterData.ray.origin.y,
        thirdRaycasterData.ray.origin.z
      );
      const thirdDirection = new Vector3(
        thirdRaycasterData.ray.direction.x,
        thirdRaycasterData.ray.direction.y,
        thirdRaycasterData.ray.direction.z
      );
      thirdRaycaster.ray.origin.copy(thirdOrigin);
      thirdRaycaster.ray.direction.copy(thirdDirection);
      thirdRaycaster.near = thirdRaycasterData.near;
      thirdRaycaster.far = thirdRaycasterData.far;
      const fourthRaycasterData = {
        ray: {
          origin: { x: 0.9, y: -0.95, z: -0.5 },
          direction: { x: 0, y: 1, z: 0 },
        },
        near: 0,
        far: 20,
      };
      const fourthRaycaster = new Raycaster();
      fourthRaycaster.far = 20;
      const fourthRaycasterStart = new Vector3(0, 0, 0);
      const fourthOrigin = new Vector3(
        fourthRaycasterData.ray.origin.x,
        fourthRaycasterData.ray.origin.y,
        fourthRaycasterData.ray.origin.z
      );
      const fourthDirection = new Vector3(
        fourthRaycasterData.ray.direction.x,
        fourthRaycasterData.ray.direction.y,
        fourthRaycasterData.ray.direction.z
      );
      fourthRaycaster.ray.origin.copy(fourthOrigin);
      fourthRaycaster.ray.direction.copy(fourthDirection);
      fourthRaycaster.near = fourthRaycasterData.near;
      fourthRaycaster.far = fourthRaycasterData.far;

      setHeightControls([
        firstRaycaster,
        secondRaycaster,
        thirdRaycaster,
        fourthRaycaster,
      ]);
      setElements([
        {
          x: -1,
          y: "-0.81533",
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1.6095000000000002,
          y: 0.37416500000000014,
          z: -0.5,
          model: "Model_171000_272833",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.2094999999999996,
          y: 0.37416500000000014,
          z: -0.5,
          model: "Model_181000_272833",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1,
          y: -0.5449999990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -1,
          y: -0.6796649990844726,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.10000000000000003,
          y: "-0.67967",
          z: -0.5,
          model: "Model_110210_600x493_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 0.49999999999999994,
          y: "-0.67967",
          z: -0.5,
          model: "Model_120210_600x493_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.4,
          y: -0.95,
          z: -0.5,
          model: "Model_151200_2400",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1,
          y: "-0.27265",
          z: -0.5,
          model: "Model_140710_400x12767_offen_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1,
          y: -0.95,
          z: -0.5,
          model: "Model_150200_400",
          griffleiste: false,
          sockel: true,
        },
        {
          x: -1.3,
          y: 0.40466500183105475,
          z: -0.5,
          model: "Model_110310_600x1548_fb",
        },
        {
          x: -0.7,
          y: -0.1380049981689453,
          z: -0.5,
          model: "Model_140310_600x4927_fb",
        },
        {
          x: -0.7,
          y: 0.4046550036621094,
          z: -0.5,
          model: "Model_140310_600x4927_fb",
        },
        {
          x: -0.7,
          y: 0.13332500366210942,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.10000000000000003,
          y: 0.4056600170898438,
          z: -0.5,
          model: "Model_110310_600x1548_fb",
        },
        {
          x: 0.49999999999999994,
          y: 0.4056600018310547,
          z: -0.5,
          model: "Model_120310_600x1548_fb",
        },
        {
          x: -0.40000000000000013,
          y: -0.40833999816894534,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 1,
          y: 1.084050036621094,
          z: -0.5,
          model: "Model_140710_400x12767_offen_fb",
        },
        {
          x: 1,
          y: 0.4057000366210938,
          z: -0.5,
          model: "Model_160200_400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.7,
          y: 0.947315005493164,
          z: -0.5,
          model: "Model_120210_600x493_fb",
        },
        {
          x: -0.7,
          y: 0.6759850054931641,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -1.3,
          y: 1.4899949407958986,
          z: -0.5,
          model: "Model_110210_600x493_fb",
        },
        {
          x: -0.7,
          y: 1.4899750073242186,
          z: -0.5,
          model: "Model_120210_600x493_fb",
        },
        {
          x: -0.10000000000000003,
          y: 1.4909899560546875,
          z: -0.5,
          model: "Model_110210_600x493_fb",
        },
        {
          x: 0.49999999999999994,
          y: 1.4909900018310547,
          z: -0.5,
          model: "Model_120210_600x493_fb",
        },
        {
          x: -0.40000000000000013,
          y: 1.2196600018310548,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
      ]);
      setElementsRendering([
        {
          x: -1,
          y: "-0.81533",
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1.6095000000000002,
          y: 0.37416500000000014,
          z: -0.5,
          model: "Model_171000_272833",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1.2094999999999996,
          y: 0.37416500000000014,
          z: -0.5,
          model: "Model_181000_272833",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1,
          y: -0.5449999990844726,
          z: -0.5,
          model: "Model_130210_1200x19133_schub",
        },
        {
          x: -1,
          y: -0.6796649990844726,
          z: -0.5,
          model: "Model_160600_1200",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.10000000000000003,
          y: "-0.67967",
          z: -0.5,
          model: "Model_110210_600x493_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 0.49999999999999994,
          y: "-0.67967",
          z: -0.5,
          model: "Model_120210_600x493_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: -0.4,
          y: -0.95,
          z: -0.5,
          model: "Model_151200_2400",
          griffleiste: false,
          sockel: true,
        },
        {
          x: 1,
          y: "-0.27265",
          z: -0.5,
          model: "Model_140710_400x12767_offen_fb",
          griffleiste: false,
          sockel: false,
        },
        {
          x: 1,
          y: -0.95,
          z: -0.5,
          model: "Model_150200_400",
          griffleiste: false,
          sockel: true,
        },
        {
          x: -1.3,
          y: 0.40466500183105475,
          z: -0.5,
          model: "Model_110310_600x1548_fb",
        },
        {
          x: -0.7,
          y: -0.1380049981689453,
          z: -0.5,
          model: "Model_140310_600x4927_fb",
        },
        {
          x: -0.7,
          y: 0.4046550036621094,
          z: -0.5,
          model: "Model_140310_600x4927_fb",
        },
        {
          x: -0.7,
          y: 0.13332500366210942,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.10000000000000003,
          y: 0.4056600170898438,
          z: -0.5,
          model: "Model_110310_600x1548_fb",
        },
        {
          x: 0.49999999999999994,
          y: 0.4056600018310547,
          z: -0.5,
          model: "Model_120310_600x1548_fb",
        },
        {
          x: -0.40000000000000013,
          y: -0.40833999816894534,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: 1,
          y: 1.084050036621094,
          z: -0.5,
          model: "Model_140710_400x12767_offen_fb",
        },
        {
          x: 1,
          y: 0.4057000366210938,
          z: -0.5,
          model: "Model_160200_400",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -0.7,
          y: 0.947315005493164,
          z: -0.5,
          model: "Model_120210_600x493_fb",
        },
        {
          x: -0.7,
          y: 0.6759850054931641,
          z: -0.5,
          model: "Model_160300_600",
          griffleiste: true,
          sockel: false,
        },
        {
          x: -1.3,
          y: 1.4899949407958986,
          z: -0.5,
          model: "Model_110210_600x493_fb",
        },
        {
          x: -0.7,
          y: 1.4899750073242186,
          z: -0.5,
          model: "Model_120210_600x493_fb",
        },
        {
          x: -0.10000000000000003,
          y: 1.4909899560546875,
          z: -0.5,
          model: "Model_110210_600x493_fb",
        },
        {
          x: 0.49999999999999994,
          y: 1.4909900018310547,
          z: -0.5,
          model: "Model_120210_600x493_fb",
        },
        {
          x: -0.40000000000000013,
          y: 1.2196600018310548,
          z: -0.5,
          model: "Model_161200_2400",
          griffleiste: true,
          sockel: false,
        },
      ]);
      setElementsMaterials([
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSeitenwangenTexture,
          griffleiste: false,
          sockel: false,
          wange: true,
        },
        {
          front: null,
          korpus: globalSeitenwangenTexture,
          griffleiste: false,
          sockel: false,
          wange: true,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSockelTexture,
          griffleiste: false,
          sockel: true,
          wange: false,
        },
        {
          front: null,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalSockelTexture,
          griffleiste: false,
          sockel: true,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: globalFrontenTexture,
          korpus: globalKorpusTexture,
          griffleiste: false,
          sockel: false,
          wange: false,
        },
        {
          front: null,
          korpus: globalGriffleistenTexture,
          griffleiste: true,
          sockel: false,
          wange: false,
        },
      ]);
      setTotalHeight(2.74);
      setCompleteWidth(2.8);
      setIsHeightEqual(true);
    } else {
      setElements([]);
      setElementsMaterials([]);
      setIsHeightEqual(false);
    }
  }, [variationAdded]);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    if (handleShowVariations) {
      setHandleShowVariations(false);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleWizardReady = () => {
    setWizardReady(true);
  };

  const handlePresetSelection = (presetName) => {
    setWizardPreset(presetName);
  };

  const handleElementChange = (element) => {
    setElementToApplyMaterialOn(element);
  };

  const handleMaterialSelection = (material) => {
    switch (elementToApplyMaterialOn) {
      case "Fronten":
        setFrontenTexture(material);
        break;

      case "Korpus":
        setKorpusTexture(material);
        break;

      case "Griffleisten":
        setGriffleistenTexture(material);
        break;

      case "Sockel":
        setSockelTexture(material);
        break;

      default:
        break;
    }
  };

  // useEffect(() => {
  //   const imageLoader = new THREE.ImageLoader();
  //   const imageUrls = Object.keys(imagepaths);
  //   imageUrls.push("images/x.svg");
  //   console.log(imageUrls);

  //   if (imageUrls) {
  //     imageUrls.forEach((imageUrl) => {
  //       imageLoader.load(imageUrl);
  //     });
  //   }

  //   function preloadImages(imageSources) {
  //     return Promise.all(
  //       imageSources.map((src) => {
  //         return new Promise((resolve, reject) => {
  //           const image = new Image();
  //           image.src = src;
  //           image.onload = resolve;
  //           image.onerror = reject;
  //         });
  //       })
  //     );
  //   }

  //   preloadImages(imageSourcesToPreload);

  //   // preloadImages(imageUrls, () => {
  //   //   setImagesLoaded(true); // All images are loaded
  //   // });
  // }, []);

  const Screen1 = () => {
    return (
      <>
        {showInspirations && (
          <button
            className={btnStyles.wizardBlackBackButton}
            onClick={() => {
              setShowInspirations(false);
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/icons/circle-chevron-right-solid-white.svg"
              }
            />
          </button>
        )}
        {showInspirations ? (
          <div className={`${styles.inspirationsSlide}`}>
            <Carousel controls touch indicators data-bs-theme="dark">
              <Carousel.Item>
                <img
                  className="d-block"
                  src={process.env.PUBLIC_URL + "/images/option1.jpg"}
                  alt="Schrankmodel"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={process.env.PUBLIC_URL + "/images/option2.jpg"}
                  alt="Schrankmodel"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block"
                  src={process.env.PUBLIC_URL + "/images/option3.jpg"}
                  alt="Schrankmodel"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        ) : null}

        <div className={styles.wrapper}>
          {handleShowVariations && (
            <button
              className={btnStyles.wizardBackButton}
              onClick={handlePrevious}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-chevron-right-solid-white.svg"
                }
              />
            </button>
          )}

          <div className={styles.wizardimg}></div>
          <div className={styles.wizardwrapper}>
            <div className={styles.wizardcontainer}>
              {handleShowVariations ? (
                <>
                  <h2>Vorlagen</h2>
                  <p className={styles.introQuestion}>
                    Wähle die Vorlage, mit der Du starten möchtest.
                  </p>

                  <div className={styles.variationsContainer}>
                    <div
                      className={`${styles.variationsWrapper} ${configuratorStyles.pointer}`}
                      onClick={() => setVariationToAdd("Option1")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/option1.jpg"}
                        alt="Option 1"
                      />
                      {variationToAdd === "Option1" && (
                        <div
                          className={styles.addSymbol}
                          onClick={() => {
                            setVariationAdded("Option1");
                            setTimeout(() => {
                              handleWizardReady();
                              setHandleShowVariations(false);
                            }, 500);
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/circle-plus-solid-white.svg"
                            }
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className={`${styles.variationsWrapper} ${configuratorStyles.pointer}`}
                      onClick={() => setVariationToAdd("Option2")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/option2.jpg"}
                        alt="Option 2"
                      />
                      {variationToAdd === "Option2" && (
                        <div
                          className={styles.addSymbol}
                          onClick={() => {
                            setVariationAdded("Option2");
                            setTimeout(() => {
                              handleWizardReady();
                              setHandleShowVariations(false);
                            }, 500);
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/circle-plus-solid-white.svg"
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.variationsWrapper} ${configuratorStyles.pointer}`}
                      onClick={() => setVariationToAdd("Option3")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/option3.jpg"}
                        alt="Option 2"
                      />
                      {variationToAdd === "Option3" && (
                        <div
                          className={styles.addSymbol}
                          onClick={() => {
                            setVariationAdded("Option3");
                            setTimeout(() => {
                              handleWizardReady();
                              setHandleShowVariations(false);
                            }, 500);
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/circle-plus-solid-white.svg"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.variationsText}>
                    <p>
                      Nicht die passende Vorlage dabei? Dann konfiguriere deinen
                      Schrank so wie du ihn haben möchtest.
                    </p>
                    <div
                      className={configuratorStyles.pointer}
                      onClick={() => {
                        setElements([]);
                        setElementsMaterials([]);
                        setElementsRendering([]);
                        setCompleteWidth(0);
                        setTotalHeight(0);
                        setElementToAdd("");
                        setHeightControls([]);
                        handleWizardReady();
                        setHandleShowVariations(false);
                      }}
                    >
                      <p className={styles.optionsCaption}>
                        Von vorne beginnen{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/circle-chevron-right-solid-brown.svg"
                          }
                          className={styles.optionIcon}
                        />
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.headline}>Schranksystem</p>
                  <h2 className={styles.desaroHeadline}>Desaro</h2>
                  <p className={styles.introQuestion}>
                    Wie möchtest du deinen Desaro Kleiderschrank planen?
                  </p>
                  <div className={styles.optionswrapper}>
                    <div
                      className={`${styles.singleOption} ${configuratorStyles.pointer}`}
                      onClick={() => {
                        setElements([]);
                        setElementsMaterials([]);
                        setElementsRendering([]);
                        setCompleteWidth(0);
                        setTotalHeight(0);
                        setElementToAdd("");
                        setHeightControls([]);
                        handleWizardReady();
                      }}
                    >
                      <div className={styles.startNewWrapper}></div>
                      <span className={styles.optionsCaption}>
                        Von vorne beginnen{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/circle-chevron-right-solid.svg"
                          }
                          className={styles.optionIcon}
                        />
                      </span>
                    </div>

                    <div
                      className={`${styles.singleOption} ${configuratorStyles.pointer}`}
                      onClick={() => {
                        setHandleShowVariations(true);
                      }}
                    >
                      <div className={styles.variations}></div>
                      <span className={styles.optionsCaption}>
                        Mit Vorlage starten{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/circle-chevron-right-solid.svg"
                          }
                          className={styles.optionIcon}
                        />
                      </span>
                    </div>

                    <div
                      className={`${styles.singleOption} ${styles.widerSingleOption} ${configuratorStyles.pointer}`}
                      onClick={() => {
                        setShowInspirations(true);
                      }}
                    >
                      <div className={styles.inspirations}></div>
                      <span className={styles.optionsCaption}>
                        Inspirationen anschauen{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/circle-chevron-right-solid.svg"
                          }
                          className={styles.optionIcon}
                        />
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className={styles.wizardbuttonrowsingle}></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Screen2 = () => {
    const changeTexture = (texture) => {
      // Code for changing each material of elements at once.
      if (selectedAll) {
        switch (isSelectedElement) {
          case "Fronten":
            setGlobalFrontenTexture(texture);

            setElementsMaterials((prevArray) => {
              return prevArray.map((item) => {
                if (item.front !== null) {
                  return { ...item, front: texture };
                }
                return item;
              });
            });
            break;

          case "Korpus":
            setGlobalKorpusTexture(texture);

            setElementsMaterials((prevArray) => {
              return prevArray.map((item) => {
                if (
                  item.griffleiste === false &&
                  item.sockel === false &&
                  item.wange === false
                ) {
                  return { ...item, korpus: texture };
                }
                return item;
              });
            });
            break;

          case "Griffleisten":
            setGlobalGriffleistenTexture(texture);

            setElementsMaterials((prevArray) => {
              return prevArray.map((item) => {
                if (item.griffleiste === true) {
                  return { ...item, korpus: texture };
                }
                return item;
              });
            });
            break;

          case "Sockel":
            setGlobalSockelTexture(texture);

            setElementsMaterials((prevArray) => {
              return prevArray.map((item) => {
                if (item.sockel === true) {
                  return { ...item, korpus: texture };
                }
                return item;
              });
            });
            break;

          case "Seitenwangen":
            setGlobalSeitenwangenTexture(texture);

            setElementsMaterials((prevArray) => {
              return prevArray.map((item) => {
                if (item.wange === true) {
                  return { ...item, korpus: texture };
                }
                return item;
              });
            });
            break;

          default:
            break;
        }
      } else {
        // code for individual material selection or seitenwangen selection
        switch (isSelectedElement) {
          case "Fronten":
            setFrontenTexture(texture);

            setElementsMaterials((prevArray) => {
              return prevArray.map((item, index) => {
                if (index === indexOfModel) {
                  return { ...item, front: texture };
                }
                return item;
              });
            });
            break;

          case "Korpus":
            if (elements?.[indexOfModel]?.model?.startsWith("Model_17")) {
              const matchingIndices = [];
              elements.forEach((element, index) => {
                if (
                  element.model.startsWith("Model_17") ||
                  element.model.startsWith("Model_18")
                ) {
                  matchingIndices.push(index);
                }
              });

              if (matchingIndices.length > 0) {
                setKorpusTexture(texture);

                setElementsMaterials((prevArray) => {
                  return prevArray.map((item, index) => {
                    if (matchingIndices.includes(index)) {
                      return { ...item, korpus: texture };
                    }
                    return item;
                  });
                });
              }
              break;
            } else {
              setKorpusTexture(texture);
              setElementsMaterials((prevArray) => {
                return prevArray.map((item, index) => {
                  if (index === indexOfModel) {
                    return { ...item, korpus: texture };
                  }
                  return item;
                });
              });
              break;
            }

          default:
            break;
        }
      }
    };

    return (
      <>
        <div className={styles.wrapper}>
          <button
            className={btnStyles.wizardBackButton}
            onClick={handlePrevious}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/icons/circle-chevron-right-solid-white.svg"
              }
            />
          </button>
          <div className={styles.wizardimg}></div>
          <div className={styles.wizardwrapper}>
            <div className={styles.wizardwrapper_innercontainer}>
              <div className={styles.wizardcontainer}>
                <h2>Desaro</h2>
                <p className={configuratorStyles.boldFont}>
                  Wähle die passenden Farben und Materialien aus.
                </p>
                <div className={styles.elementBar}>
                  <ActiveMaterial element={"Fronten"} />
                  <ActiveMaterial element={"Korpus"} />
                  <ActiveMaterial element={"Griffleisten"} />
                  <ActiveMaterial element={"Sockel"} />
                  <ActiveMaterial element={"Seitenwangen"} />

                  {/* <p
                    onClick={() => handleElementChange("Fronten")}
                    className={
                      elementToApplyMaterialOn === "Fronten"
                        ? styles.boldElement
                        : ""
                    }
                  >
                    Fronten
                  </p>
                  <p
                    onClick={() => handleElementChange("Korpus")}
                    className={
                      elementToApplyMaterialOn === "Korpus"
                        ? styles.boldElement
                        : ""
                    }
                  >
                    Korpus
                  </p>
                  <p
                    onClick={() => handleElementChange("Griffleisten")}
                    className={
                      elementToApplyMaterialOn === "Griffleisten"
                        ? styles.boldElement
                        : ""
                    }
                  >
                    Griffleisten
                  </p>
                  <p
                    onClick={() => handleElementChange("Sockel")}
                    className={
                      elementToApplyMaterialOn === "Sockel"
                        ? styles.boldElement
                        : ""
                    }
                  >
                    Sockel
                  </p>
                  <p
                    onClick={() => handleElementChange("Seitenwangen")}
                    className={
                      elementToApplyMaterialOn === "Seitenwangen"
                        ? styles.boldElement
                        : ""
                    }
                  >
                    Seitenwangen
                  </p> */}
                </div>

                <div className={styles.materialBar}>
                  <span
                    onClick={() => {
                      setMaterialTypeWizard("alle");
                    }}
                    className={`${configuratorStyles.pointer} ${
                      materialTypeWizard === "alle" &&
                      configuratorStyles.boldFont
                    }`}
                  >
                    Alle
                  </span>
                  <span
                    onClick={() => {
                      setMaterialTypeWizard("kunststoff");
                    }}
                    className={`${configuratorStyles.pointer} ${
                      materialTypeWizard === "kunststoff" &&
                      configuratorStyles.boldFont
                    }`}
                  >
                    Kunststoffoberflächen
                  </span>
                  {isSelectedElement !== "Korpus" && (
                    <span
                      onClick={() => {
                        setMaterialTypeWizard("echtholzLack");
                      }}
                      className={`${configuratorStyles.pointer} ${
                        materialTypeWizard === "echtholzLack" &&
                        configuratorStyles.boldFont
                      }`}
                    >
                      Echtholz-/Lackoberflächen
                    </span>
                  )}
                </div>

                <div className={styles.materialoptions}>
                  {isSelectedElement === "Fronten" && (
                    <>
                      {materialTypeWizard === "alle" && (
                        <AllFrontenMaterials changeTexture={changeTexture} />
                      )}
                      {materialTypeWizard === "kunststoff" && (
                        <KunststoffFrontenMaterials
                          changeTexture={changeTexture}
                        />
                      )}
                      {materialTypeWizard === "echtholzLack" && (
                        <EchtholzLackFrontenMaterials
                          changeTexture={changeTexture}
                        />
                      )}
                    </>
                  )}

                  {isSelectedElement === "Korpus" && (
                    <>
                      <div>
                        <img
                          src={
                            imagepaths[
                              "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                            ]
                          }
                          alt="weiß"
                          onClick={() =>
                            changeTexture(
                              "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                            )
                          }
                          className={`${configuratorStyles.pointer} 
                          ${
                            selectedAll &&
                            isSelectedElement === "Korpus" &&
                            globalKorpusTexture ===
                              "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                              ? configuratorStyles.selectedMaterial
                              : ""
                          }`}
                        />
                        <p>01 Standard-Weiß</p>
                      </div>

                      <div>
                        <img
                          src={
                            imagepaths[
                              "https://app.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg"
                            ]
                          }
                          alt="vulkanschwarz"
                          onClick={() =>
                            changeTexture(
                              "https://app.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg"
                            )
                          }
                          className={`${configuratorStyles.pointer} 
                         ${
                           selectedAll &&
                           isSelectedElement === "Korpus" &&
                           globalKorpusTexture ===
                             "https://app.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg"
                             ? configuratorStyles.selectedMaterial
                             : ""
                         }`}
                        />
                        <p>02 Vulkanschwarz</p>
                      </div>
                    </>
                  )}

                  {(isSelectedElement === "Griffleisten" ||
                    isSelectedElement === "Sockel") && (
                    <>
                      {materialTypeWizard === "alle" && (
                        <>
                          <AllFrontenMaterials changeTexture={changeTexture} />
                          <div>
                            <img
                              src={
                                imagepaths[
                                  "https://app.4kantdesign.de/images/Materials/19_aluminium.jpg"
                                ]
                              }
                              alt="aluminium"
                              onClick={() =>
                                changeTexture(
                                  "https://app.4kantdesign.de/images/Materials/19_aluminium.jpg"
                                )
                              }
                              className={`${configuratorStyles.pointer} 
                              ${
                                selectedAll &&
                                isSelectedElement === "Griffleisten" &&
                                globalGriffleistenTexture ===
                                  "https://app.4kantdesign.de/images/Materials/19_aluminium.jpg"
                                  ? configuratorStyles.selectedMaterial
                                  : ""
                              }
                              ${
                                selectedAll &&
                                isSelectedElement === "Sockel" &&
                                globalSockelTexture ===
                                  "https://app.4kantdesign.de/images/Materials/19_aluminium.jpg"
                                  ? configuratorStyles.selectedMaterial
                                  : ""
                              }`}
                            />
                            <p>19 Aluminium</p>
                          </div>
                        </>
                      )}
                      {materialTypeWizard === "kunststoff" && (
                        <KunststoffFrontenMaterials
                          changeTexture={changeTexture}
                        />
                      )}
                      {materialTypeWizard === "echtholzLack" && (
                        <EchtholzLackFrontenMaterials
                          changeTexture={changeTexture}
                        />
                      )}
                    </>
                  )}

                  {isSelectedElement === "Seitenwangen" && (
                    <>
                      {materialTypeWizard === "alle" && (
                        <>
                          <AllFrontenMaterials changeTexture={changeTexture} />
                        </>
                      )}
                      {materialTypeWizard === "kunststoff" && (
                        <KunststoffFrontenMaterials
                          changeTexture={changeTexture}
                        />
                      )}
                      {materialTypeWizard === "echtholzLack" && (
                        <EchtholzLackFrontenMaterials
                          changeTexture={changeTexture}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className={btnStyles.wizardbuttonContainer}>
                  <button
                    className={btnStyles.wizardbutton}
                    onClick={handleWizardReady}
                  >
                    Zum Konfigurator
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const NavigationBullets = () => {
    const bulletStyle = {
      display: "inline-block",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: "lightgray",
      marginRight: "25px",
      cursor: "pointer",
    };

    const activeBulletStyle = {
      backgroundColor: "grey",
    };

    const handleBulletClick = (step) => {
      setCurrentStep(step);
    };

    return (
      <div className={styles.bulletpoints}>
        <div>
          <span
            style={
              currentStep === 1
                ? { ...bulletStyle, ...activeBulletStyle }
                : bulletStyle
            }
            onClick={() => handleBulletClick(1)}
          ></span>
          <span
            style={
              currentStep === 2
                ? { ...bulletStyle, ...activeBulletStyle }
                : bulletStyle
            }
            onClick={() => handleBulletClick(2)}
          ></span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {currentStep === 1 && <Screen1 />}
      {currentStep === 2 && <Screen2 />}
    </div>
  );
};

export default Wizard;
