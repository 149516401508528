import React, { createContext, useState, useContext } from "react";
import { weiß } from "../components/textures/textures";

const Context = createContext();

const ContextProvider = ({ children }) => {
  // Opening Wizard
  const [wizardReady, setWizardReady] = useState(false);
  const [wizardPreset, setWizardPreset] = useState(false);
  const [wizardMaterial, setWizardMaterial] = useState("");
  const [frontenTexture, setFrontenTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [korpusTexture, setKorpusTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [griffleistenTexture, setGriffleistenTexture] =
    useState("07_Standard_Wei_");
  const [sockelTexture, setSockelTexture] = useState("07_Standard_Wei_");
  const [globalFrontenTexture, setGlobalFrontenTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [globalKorpusTexture, setGlobalKorpusTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [globalGriffleistenTexture, setGlobalGriffleistenTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [globalSockelTexture, setGlobalSockelTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [globalSeitenwangenTexture, setGlobalSeitenwangenTexture] = useState(
    "https://app.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
  );
  const [handleShowVariations, setHandleShowVariations] = useState(false);
  const [showInspirations, setShowInspirations] = useState(false);
  const [variationToAdd, setVariationToAdd] = useState("");
  const [variationAdded, setVariationAdded] = useState("");
  const [currentYPos, setCurrentYPos] = useState(0);

  // Helpers
  const [helperX, setHelperX] = useState([0, 0, 3]);
  const [helperXTop, setHelperXTop] = useState([0, 0, 3]);
  const [helperYleft, setHelperYleft] = useState([0, 0, 3]);
  const [helperYright, setHelperYright] = useState([0, 0, 3]);
  const [helperYrightDouble, setHelperYrightDouble] = useState([0, 0, 3]);
  const [helperYdouble, setHelperYdouble] = useState([0, 0, 3]);
  const [helperYpossible, setHelperYpossible] = useState([0, 0, 3]);
  const [heightControls, setHeightControls] = useState([]);
  const [raycasterRowIntersections, setRaycasterRowIntersections] = useState(
    []
  );
  const [raycasterColumnPositionsArray, setRaycasterColumnPositionsArray] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [showSidebar, setShowSidebar] = useState(viewportWidth >= 1400);
  const [spaceUnderModel, setSpaceUnderModel] = useState(0);

  //Configurator
  const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
  const [modelsArray, setModelsArray] = useState([]);
  const [elements, setElements] = useState([]);
  const [elementsRendering, setElementsRendering] = useState([]);
  const [elementsMaterials, setElementsMaterials] = useState([]);
  const [modelBoundingBox, setModelBoundingBox] = useState();
  const [showAxesModeX, setShowAxesModeX] = useState(false);
  const [showAxesModeY, setShowAxesModeY] = useState(false);
  const [showAxesModeYleft, setShowAxesModeYleft] = useState(false);
  const [showAxesModeYright, setShowAxesModeYright] = useState(false);
  const [showAxesModeYdouble, setShowAxesModeYdouble] = useState(false);
  const [axes, setAxes] = useState("start");
  const [boundingBox, setBoundingBox] = useState({
    max: [0, 0, 0],
    min: [0, 0, 0],
  });
  const [boundingBoxArray, setBoundingBoxArray] = useState([]);
  const [currentSelectedModelRef, setCurrentSelectedModelRef] = useState();
  const [indexOfModel, setIndexOfModel] = useState();
  const [selectedAll, setSelectedAll] = useState(true);
  const [selectedModelWidth, setSelectedModelWidth] = useState();
  const [completeWidth, setCompleteWidth] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [isHeightEqual, setIsHeightEqual] = useState(false);
  const [showFronten, setShowFronten] = useState(true);
  const [sceneRerenders, setSceneRerenders] = useState(0);
  const [alreadyBought, setAlreadyBought] = useState(false);

  // Sidebar
  const [sidebarMainOption, setSidebarMainOption] = useState("Aufbau");
  const [cabinets, setCabinetes] = useState([]);
  const [shelves, setShelves] = useState([]);
  const [drawers, setDrawers] = useState([]);
  const [elementToAdd, setElementToAdd] = useState("");
  const [elementAdded, setElementAdded] = useState("");
  const [showMaterialOverview, setShowMaterialOverview] = useState(false);
  const [isSelectedElement, setIsSelectedElement] = useState("Fronten");
  const [editMode, setEditMode] = useState(false);
  const [elementToDelete, setElementToDelete] = useState([]);
  const [showCabinets, setShowCabinets] = useState(true);
  const [showShelves, setShowShelves] = useState(true);
  const [showDrawers, setShowDrawers] = useState(true);

  const [isActiveMaterial, setIsActiveMaterial] = useState("07_Standard_Wei_");
  const [isActiveMesh, setIsActiveMesh] = useState();

  // Shopify
  const [variantSkus, setVariantSkus] = useState([]);
  const [price, setPrice] = useState(0);
  const [showStockOverview, setShowStockOverview] = useState(false);

  const contextValue = {
    wizardReady, // Boolean to check if wizard presets are done. If true, go to configurator
    setWizardReady,
    wizardPreset,
    setWizardPreset,
    frontenTexture, // State that holds the currently picked texture for Fronten as string (e.g. "weiß")
    setFrontenTexture,
    korpusTexture, // State that holds the currently picked texture for Korpus as string (e.g. "weiß")
    setKorpusTexture,
    griffleistenTexture, // State that holds the currently picked texture for Griffleisten as string (e.g. "weiß")
    setGriffleistenTexture,
    sockelTexture, // State that holds the currently picked texture for Sockel as string (e.g. "weiß")
    setSockelTexture,
    globalFrontenTexture, // State that holds the currently picked texture for global fronten
    setGlobalFrontenTexture,
    globalKorpusTexture, // State that holds the currently picked texture for global korpus
    setGlobalKorpusTexture,
    globalGriffleistenTexture, // State that holds the currently picked texture for global griffleisten
    setGlobalGriffleistenTexture,
    globalSockelTexture, // State that holds the currently picked texture for global sockel
    setGlobalSockelTexture,
    globalSeitenwangenTexture, // State that holds the currently picked texture for global seitenwangen
    setGlobalSeitenwangenTexture,
    handleShowVariations, // Boolean value to control variations modal
    setHandleShowVariations,
    showInspirations, // Boolean value to control inspirations modal
    setShowInspirations,
    variationToAdd, // String value of the option that is being selected
    setVariationToAdd,
    variationAdded, // String value of the option that is being confirmed
    setVariationAdded,
    currentYPos, // Y-Position in Wizard Presets
    setCurrentYPos,

    helperX, // State that keeps track of the current positions of the X-Axes helper
    setHelperX,
    helperXTop,
    setHelperXTop,
    helperYleft,
    setHelperYleft,
    helperYright,
    setHelperYright,
    helperYrightDouble,
    setHelperYrightDouble,
    helperYdouble,
    setHelperYdouble,
    helperYpossible,
    setHelperYpossible,
    heightControls,
    setHeightControls,
    raycasterRowIntersections,
    setRaycasterRowIntersections,
    raycasterColumnPositionsArray,
    setRaycasterColumnPositionsArray,
    loading,
    setLoading,
    showSidebar,
    setShowSidebar,
    spaceUnderModel, // Number that measures the distance between models on the y-axes, that sould be stacked.
    setSpaceUnderModel,
    viewportWidth,
    setViewportWidth,

    labelPosition, // x and y coordinates of a click for displaying the axes container
    setLabelPosition,
    modelsArray,
    setModelsArray,
    elements, // Array of all added Elements to the scene
    setElements,
    elementsRendering, // Copy of elements
    setElementsRendering,
    elementsMaterials, // Array of all the individual textures of each added model
    setElementsMaterials,
    modelBoundingBox, // Vector3 that holds the coordinates of the currently loaded preset in the scene
    setModelBoundingBox,
    showAxesModeX, // Boolean value to control the ability of adding model on the x axes
    setShowAxesModeX,
    showAxesModeY, // Boolean value to control the ability of adding model on the y axes
    setShowAxesModeY,
    showAxesModeYleft, // Boolean value to control the ability of adding model on the y axes but to the left side
    setShowAxesModeYleft,
    showAxesModeYright, // Boolean value to control the ability of adding model on the y axes but to the right side
    setShowAxesModeYright,
    showAxesModeYdouble, // Boolean value to control the ability of adding model on the y axes, double the size
    setShowAxesModeYdouble,

    axes, // Holds a String value of "x", "y", "yLeft", "yRight" or "yDouble" to determine, in which direction the user wants to add an element
    setAxes,
    boundingBox, // State to store bounding box of a clicked model
    setBoundingBox,
    boundingBoxArray, // Array that stores all bounding boxes
    setBoundingBoxArray,
    currentSelectedModelRef, // Reference to the current selected model
    setCurrentSelectedModelRef,
    indexOfModel, // Holds a index number of the currenct selected model as a unique identifier
    setIndexOfModel,
    selectedAll, // Boolean that checks, wheather all models have been selected
    setSelectedAll,
    selectedModelWidth,
    setSelectedModelWidth,
    completeWidth, // Holds the value of the maxium width (x-axes) of the complete model
    setCompleteWidth,
    totalHeight, // Holds the height value of the start column
    setTotalHeight,
    isHeightEqual, // Holds a boolean if all rows are of equal height
    setIsHeightEqual,
    showFronten, // Holds a boolean if all fronten are visible
    setShowFronten,
    sceneRerenders, // // Holds a boolean if the scene is ready
    setSceneRerenders,
    alreadyBought, // Holds a boolean if the  selection already has been bought
    setAlreadyBought,

    sidebarMainOption, // String that defines, what should be displayed in the Sidebar
    setSidebarMainOption,
    cabinets, // Array that stores all cabinets
    setCabinetes,
    shelves, // Array that stores all shelves
    setShelves,
    drawers, // Array that stores all drawers
    setDrawers,
    elementToAdd, // String that holds the name of the element that is currently being selected in the sidebar
    setElementToAdd,
    elementAdded, // String that holds the name of the element that is confirmed to being add to the scene
    setElementAdded,
    showCabinets,
    setShowCabinets,
    showShelves,
    setShowShelves,
    showDrawers,
    setShowDrawers,

    isActiveMaterial,
    setIsActiveMaterial,
    isActiveMesh,
    setIsActiveMesh,
    showMaterialOverview, // Boolean value that checks, wheater the user wants to gain access to the the material overview or not
    setShowMaterialOverview,
    isSelectedElement, // State that keeps track of the currently picked Material that the user wants to change under Material (first row)
    setIsSelectedElement,
    editMode, // Boolean value that checks, if the user is in EditMode or not
    setEditMode,
    elementToDelete, // Holds the current element the user wants to delete
    setElementToDelete,

    variantSkus, // Array with all variant skus
    setVariantSkus,
    price, // Number that shows total price
    setPrice,
    showStockOverview, // Boolean that controls visibility of stock summary
    setShowStockOverview,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useGlobalContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useContext must be used within a ContextProvider");
  }
  return context;
};

export { ContextProvider, useGlobalContext };
