import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import styles from "../../styles/configurator.module.scss";
import btnStyles from "../../styles/btnStyles.module.scss";
import inventory from "./inventory.json";
import Client from "@shopify/buy-button-js";
import axios from "axios";
import { Shopify } from "@shopify/shopify-api";

const StockOverview = () => {
  const {
    variantSkus,
    price,
    showStockOverview,
    setShowStockOverview,
    setLoading,
    setShowSidebar,
    heightControls,
    elements,
    elementsRendering,
    elementsMaterials,
    totalHeight,
    completeWidth,
  } = useGlobalContext();
  const [summary, setSummary] = useState({});

  const client = Client.buildClient({
    domain: "4kant-design.myshopify.com",
    storefrontAccessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  let selectionUrl;

  const handlePostRequest = async () => {
    const sessionId = generateRandomString(15);
    const createdAt = new Date().toISOString();

    const elementToAdd = elements[0].model;

    const postData = {
      sessionId,
      createdAt,
      elementToAdd,
      heightControls,
      elements,
      elementsRendering,
      elementsMaterials,
      totalHeight,
      completeWidth,
    };

    try {
      const response = await fetch("/api/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        console.log("Post request successful!");
        handleCheckout();
        // Handle success as needed
      } else {
        console.error("Post request failed.");
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error during post request:", error);
    }
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    selectionUrl = result;
    return result;
  };

  useEffect(() => {
    const combinedVariants = variantSkus.reduce((summary, sku) => {
      if (summary[sku]) {
        summary[sku].quantity += 1; // Increment quantity if SKU exists in the summary
      } else {
        summary[sku] = { quantity: 1 }; // Initialize quantity to 1 if SKU doesn't exist in the summary
      }
      return summary;
    }, {});

    const variantsWithHandleAndPrice = Object.keys(combinedVariants).reduce(
      (acc, sku) => {
        const foundItem = inventory.find((item) => item["Variant SKU"] === sku);
        if (foundItem) {
          const quantity = combinedVariants[sku].quantity;
          const price = foundItem["Variant Price"] || 0; // Default price to 0 if not available
          const stockPrice = price * quantity;

          acc[sku] = {
            quantity,
            handle: foundItem.Handle,
            stockPrice,
          };
        }
        return acc;
      },
      {}
    );
    setSummary(variantsWithHandleAndPrice);
  }, [variantSkus]);

  const handleCheckout = async () => {
    const checkoutId = await createCheckout();
    const foundVariantIDs = [];
    let productsData = {};

    const collectionId = "gid://shopify/Collection/504502026504";

    const fetchCollectionAndProcessProducts = async () => {
      const collection = await client.collection.fetchWithProducts(
        collectionId,
        { productsFirst: 200 }
      );
      productsData = collection.products;

      for (const sku of variantSkus) {
        for (const product of productsData) {
          for (const variant of product.variants) {
            if (variant.sku === sku) {
              foundVariantIDs.push(variant.id);
              productsData[sku] = product;
              break;
            }
          }
        }
      }
    };

    await fetchCollectionAndProcessProducts();
    const lineItemsToAdd = [];

    for (const sku of foundVariantIDs) {
      const existingLineItem = lineItemsToAdd.find(
        (item) => item.variantId === `${sku}`
      );
      if (existingLineItem) {
        // Variant already exists, update quantity
        existingLineItem.quantity += 1;
      } else {
        // Variant not found, add a new line item
        lineItemsToAdd.push({
          variantId: `${sku}`,
          quantity: 1,
        });
      }
    }

    if (selectionUrl) {
      // Add items to the checkout
      const note = `https://app.4kantdesign.de/?selection=${selectionUrl}`;
      const input = {
        customAttributes: [{ key: "Selektions-URL", value: note }],
      };

      client.checkout.updateAttributes(checkoutId, input).then((checkout) => {
        // Add items to the checkout after updating attributes
        client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((checkout) => {
            redirectToCheckout(checkoutId);
          });
      });
    }
  };

  const redirectToCheckout = async (checkoutId) => {
    const checkout = await client.checkout.fetch(checkoutId);
    window.location.href = checkout.webUrl;
  };

  const createCheckout = async () => {
    const checkout = await client.checkout.create();
    return checkout.id;
  };

  return (
    <>
      {showStockOverview ? (
        <div
          className={`${styles.modalBackground} ${
            showStockOverview ? "" : styles.hidePopupModal
          }`}
        >
          <div className={`${styles.popupModal} ${styles.summaryModal}`}>
            <p>Deine Konfiguration</p>
            <div className={styles.summaryContainer}>
              {summary &&
                Object.entries(summary).map(([sku, data]) => (
                  <div key={sku} className={styles.stockItem}>
                    <span>
                      <span className={`${styles.quantity} ${styles.boldFont}`}>
                        {data.quantity}x
                      </span>{" "}
                      {data.handle}
                    </span>
                    <span className={styles.boldFont}>
                      {data.stockPrice.toFixed(2)} €
                    </span>
                  </div>
                ))}
            </div>
            <div className={styles.gesamtsumme}>
              <p>Summe: {price} €</p>
              <p>(inkl. 19% MwSt.)</p>
            </div>
            <button
              className={btnStyles.whiteModalButton}
              onClick={() => {
                setShowStockOverview(false);
              }}
            >
              Konfiguration ändern
            </button>
            <button
              className={btnStyles.blackModalButton}
              onClick={() => {
                setShowSidebar(false);
                setShowStockOverview(false);
                setLoading(true);
                handlePostRequest();
              }}
            >
              Konfiguration bestellen
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StockOverview;
